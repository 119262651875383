import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import last from "lodash/last";

import service from "./api/HTTPClient";

import App from './App';
import ErrorBoundary from './containers/ErrorBoundary';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

const hash = last(window.location?.pathname?.split?.("/")) ?? "";

service.updateInstanceSuffix(hash);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.StrictMode>
);

