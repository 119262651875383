import { FC, useMemo } from "react";
import { PageHeader, Col, Row, Tag, Typography } from "antd";

import Container from '../../containers/Container';
import { isMobile } from "react-device-detect";

const { Link } = Typography;

const Page: FC<PageProps> = ({ title, subTitle, tags = [], isLoading, extra, action, children }): JSX.Element => {
	const tagsComponent = useMemo(() => {
		return tags.map(({ title, color = "processing" }: Tag): any => {
			return (
				<Tag color={color}>
					{title}
				</Tag>
			)
		});
	}, [tags]);

	return (
		<>
			<Row style={{ height: "100%", flex: 1 }}>
				<Col span={24}>
					<Container>
						<Col span={24}>
							<PageHeader
								title={title}
								className="site-page-header"
								// subTitle={[<Col sm={24} className={"header-subtitle"}>{subTitle}</Col>]}
								tags={tagsComponent}
								footer={[<Col xs={24} className={"header-subtitle"}>{subTitle}</Col>]}
								extra={extra}
							/>
						</Col>
					</Container>
				</Col>

				<Col span={24}>
					<Container>
						{children}
					</Container>
				</Col>

				<Col span={24} style={{ marginTop: "auto" }}>
					<Container>
						<Col span={24}>
							<Row justify={"center"}>
								<div style={{ fontSize: 12 }}>
									Документ формируется в сервисе <Link href="https://cloudsigns.ru" target="_blank">cloudsigns.ru</Link>, { isMobile ? <br /> : "" }подробнее о сервисе можете ознакомиться <Link href="https://cloudsigns.ru/about-service/" target="_blank">тут</Link>.
								</div>
							</Row>
						</Col>
					</Container>
					{
						!!action && (
							<Row style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
								<Col className={"ant-modal-footer"} span={24}>
									<Row justify={"end"}>
										{action}
									</Row>
								</Col>
							</Row>
						)
					}
				</Col>
			</Row>
		</>
	)
}

export default Page;

Page.displayName = "Page";
