import { FC } from "react";
import { isSafari } from "react-device-detect";

type PDFFile = {
	id: string,
	title: string,
	url: string
};

const PDFViewer: FC<PDFFile> = ({ id, title, url }): JSX.Element => {
	return (
		<>
			{
				isSafari && (
					<object
						id={id}
						name={title}
						type={"application/pdf"}
						data={`${url}#view=FitV`}
						width={"100%"}
						height={"100%"}
						style={{ height: "100%", width: "100%" }}
					>
						<param
							name={"view"}
							value={"fitH"}
						/>

						<param
							name={"zoom"}
							value={"10"}
						/>
					</object>
				)
			}

			{
				!isSafari && (
					<iframe
						id={id}
						title={title}
						src={`${url}#view=FitV`}
						frameBorder="0"
						width={"100%"}
						height={"100%"}
					/>
				)
			}
		</>
	)
}

export default PDFViewer;

PDFViewer.displayName = "PDFViewer";
