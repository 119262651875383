import { useRequest } from "ahooks";
import { AxiosResponse } from "axios";

import service from "../../api/HTTPClient";

const useDownload = (options?: ObjectRecord) => {
    const request = (formData: ObjectRecord) => {
		return new Promise((resolve, reject) => {
			service.put("download", formData)
				.then((response: AxiosResponse) => {
					const url = response?.data?.url;
					const filename = response?.data?.filename ?? url;

					if(url) {
						service.request({
							url,
							responseType: "blob"
						})
							.then((response: AxiosResponse<Blob>) => {
								const blob = response?.data ?? new Blob();
								const url = window.URL.createObjectURL(blob);

								resolve({
									url,
									filename
								});
							})
							.catch(reject)

						return;
					}

					reject(new Error("File not exist."))
				})
				.catch(reject);
		});
	};

	return useRequest(request, options)
}

export default useDownload;
