/* eslint-disable */
/* @ts-ignore */
import Created from "./Created";
import Waiting from "./Waiting";
import Signed from "./Signed";
import Goskluch from "./Goskluch";
import TimedOut from "./TimedOut";
import Cancelled from "./Cancelled";
import ErrorState from "./ErrorState";



const Empty = () => {
	return (
		<div>
			Empty
		</div>
	)
}

const statesMap: ObjectRecord = {
	created: Created,
	waiting_for_sign: Waiting,
	signed: Signed,
	goskluch_timedout: TimedOut,
	goskluch_cancelled: Cancelled,
	goskluch_error: ErrorState,

	get(state: string): JSX.Element | string {
		return this[state] ?? Created;
	}
};

export default statesMap;
