import { FC } from "react";
import { Row, Col, Divider } from "antd";
import { useRequest } from "ahooks";

import State from "../../../containers/State";

const Waiting: FC<StateProps> = ({ onFinish, title, subtitle, description, icon }): JSX.Element => {
	const {
		loading: isStateLoading,
		data: stateData
	} = useRequest(
		onFinish,
		{
			pollingInterval: 5000,
			pollingWhenHidden: false,
			pollingErrorRetryCount: 10
		}
	);

	console.info({ isStateLoading, stateData })

	return (
		<State>
			<Row gutter={[16, 16]}>
				<Col xs={24} sm={8} style={{display: 'flex', justifyContent: 'center'}}>
					<Row align={"middle"}>
						{icon}
					</Row>
				</Col>
				<Col xs={24} sm={16}>
					<div className={"state-body"}>
						<div>
							Документ направлен в мобильное приложение Госключ, подпишите его в мобильном приложении.
						</div>
						<div>
							Документ будет доступен в мобильном приложении Госключ в течение 24 часов.
						</div>
						<Divider />
						<div>
							Статус на этой странице обновится в течении 1 минуты после подписания
						</div>
					</div>
				</Col>
			</Row>
		</State>
	);
}

export default Waiting;

Waiting.displayName = "WaitingState";
