import { FC } from "react";
import { Col, Row } from "antd";

import State from "../../../containers/State";

const ErrorState: FC<StateProps> = ({ title, description, icon }): JSX.Element => {
	return (
		<State>
			<Row gutter={[16, 16]}>
				<Col xs={24} sm={8} className={"centered-content"}>
					<Row align={"middle"}>
						{icon}
					</Row>
				</Col>
				<Col xs={24} sm={16} className={"centered-content"}>
					<Row gutter={[0, 16]} className={"state-body_centered"}>
						<Col span={24} className={"text_bold"}>
							{title}
						</Col>
						<Col span={24} className={"state-body"}>
							{description}
						</Col>
					</Row>
				</Col>
			</Row>
		</State>
	);
}

export default ErrorState;

ErrorState.displayName = "ErrorState";
