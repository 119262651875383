import { ReactNode, Component } from "react";
import { Alert, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

interface Props {
	children?: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
	error: ObjectRecord;
	errorInfo: ObjectRecord;
}

class ErrorBoundary extends Component<Props, ErrorBoundaryState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			hasError: false,
			error: {},
			errorInfo: {}
		} as ErrorBoundaryState;
	}

	public static getDerivedStateFromError(error: Error) {
		console.log({ error });

		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ObjectRecord) {
		this.setState({ error, errorInfo });

		console.error({error, errorInfo});
	}

	render(): ReactNode {
		const { hasError, error, errorInfo } = this.state;
		const { children } = this.props;

		if(error && errorInfo) {
			console.info({ error, errorInfo });
		}

		if(hasError) {
			return (
				<>
					<Button
						type={"primary"}
						onClick={() => this.setState({ hasError: false })}
						icon={<ReloadOutlined />}
						style={{
							width: "100%",
							marginBottom: 10
						}}
					>
						Обновить
					</Button>
					<Alert
						message={this.state.error?.message}
						description={this.state.error?.stack}
						type="error"
						showIcon
					/>
				</>
			)
		}

		return children;
	}
}

export default ErrorBoundary;
