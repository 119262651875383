import { ReactNode } from 'react';

function Container({ children }: { children: ReactNode }): JSX.Element {
    return (
        <div className='container'>
            {children}
        </div>
    );
}

export default Container;
