import { FC } from "react";
import { Row, Col, Divider, Button } from "antd";

import State from "../../../containers/State";

const Signed: FC<StateProps> = ({ title, subtitle, icon, result }): JSX.Element => {
	return (
		<State>
			<Row gutter={[16, 16]}>
				<Col xs={24} sm={8} style={{display: 'flex', justifyContent: 'center'}}>
					<Row align={"middle"}>
						{icon}
					</Row>
				</Col>
				<Col xs={24} sm={16}>
					<div className={"state-body"}>
						<div>
							{title}
						</div>
						<div>
							<span className='text-indent'>Документ подписан, теперь Вы можете скачать</span>
							<Button
								target='_blank'
								type="link"
								download={"файл документа"}
								href={result?.document}
								className='text-indent'
								style={{ padding: 0 }}
							>
								файл документа
							</Button>
							<span className='text-indent'>и</span>
							<Button
								target='_blank'
								type="link"
								download={"файл подписи"}
								href={result?.signature}
								className='text-indent'
								style={{ padding: 0 }}
							>
								файл подписи
							</Button>
							<span>документа.</span>
						</div>
						<Divider />
						<div>
							{subtitle}
							Также вы можете скачать подписанные файлы в личном кабинете Госуслуг.
						</div>
					</div>
				</Col>
			</Row>
		</State>
	);
}

export default Signed;

Signed.displayName = "SignedState";
