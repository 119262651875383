import { FC, createElement, useCallback, useEffect, useMemo, ReactElement, ReactNode, CSSProperties } from "react";
import { Button, Modal } from "antd";
import { FileSyncOutlined, CloseOutlined, FileDoneOutlined, ExceptionOutlined, FieldTimeOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { isMobile, isSafari } from "react-device-detect";
import Cookies from "js-cookie";

import Page from './components/Page';
import States from './components/States';

import { useStatus } from './hooks';

import "./style.less";

const handleSetCookie = (event: any) => {
	const session = event?.originalEvent?.data?.data?.session ?? {};

	Cookies.set(session?.key, session?.value, { path: "/", domain: "cloudsigns.ru" });
};

const Simple: FC<{ children: ReactElement, footer: ReactElement, onCancel: () => void }> = ({ children, onCancel }): JSX.Element  => {
	return (
		<div style={{ position: "absolute", height: "100%", padding: 24, background: "#FFFFFF" }}>
			<Button
				style={{ position: "absolute", top: 8, right: 8 }}
				icon={<CloseOutlined />}
				onClick={onCancel}
				type={"link"}
			/>
			{children}
		</div>
	);
};

function App() {
	const {
		loading: isStateLoading,
		data: stateData = {} as ObjectRecord,
		refresh: stateRefresh,
		refreshAsync: stateRefreshAsync
	} = useStatus();

	const organizationName = stateData?.data?.organization_name ?? "";
	const state = stateData?.data?.state ?? "";
	const name = stateData?.data?.name ?? "";
	const result = stateData?.data?.result;

	const handleClose = useCallback(() => {
		switch(state) {
			case "created":
			case "waiting_for_sign":
				window.parent?.postMessage?.({
					action: "cancel"
				}, "*");

				return;
			default:
				window.parent?.postMessage?.({
					action: "close"
				}, '*');
		}
	}, [state]);

	useEffect(() => {
		if(isSafari) {
			window.addEventListener("message", handleSetCookie);

			return () => window.removeEventListener("message", handleSetCookie);
		}
	}, []);

	useEffect(() => {
		const result = stateData?.data?.result ?? {};
		const error = stateData?.data?.error;

		switch(state) {
			case "signed":
				window.parent?.postMessage?.({
					action: "result",
					data: result
				}, "*");

				return;
			case "goskluch_timedout":
			case "goskluch_error":
			case "goskluch_cancelled":
				window.parent?.postMessage?.({
					action: "error",
					data: error
				}, "*");

				return;
			default:
				console.info("state: ", state);
		}
	}, [result, state, stateData?.data?.error, stateData?.data?.result]);

	const action: ReactNode = useMemo(() => {
		switch(state) {
			case "waiting_for_sign":
				return (
					<Button
						loading={isStateLoading}
						disabled={isStateLoading}
						key="1"
						type="primary"
						onClick={stateRefresh}
					>
						Обновить
					</Button>
				);
			case "created":
			case "signed":
			case "goskluch_timedout":
			case "goskluch_error":
			case "goskluch_cancelled":
				return (
					<Button
						disabled={isStateLoading}
						key="1"
						type="primary"
						onClick={handleClose}
					>
						Закрыть
					</Button>
				);

			default:
					return null;
		}
	}, [handleClose, isStateLoading, state, stateRefresh]);

	const icon: ReactElement = useMemo((): JSX.Element => {
		const iconStyle: CSSProperties = { fontSize: 150, color: "#FFA39E", display: "contents" };

		switch(state) {
			case "waiting_for_sign":
				return (
					<FileSyncOutlined
						style={{
							...iconStyle,
							color: "#FAAD14"
						}}
					/>
				);
			case "signed":
				return (
					<FileDoneOutlined
						style={{
							...iconStyle,
							color: "#B7EB8F"
						}}
					/>
				);
			case "goskluch_timedout":
				return (
					<FieldTimeOutlined
						style={iconStyle}
					/>
				);
			case "goskluch_cancelled":
			case "goskluch_error":
				return (
					<ExclamationCircleOutlined
						style={iconStyle}
					/>
				);
			default:
				return (
					<ExceptionOutlined
						style={iconStyle}
					/>
				);
		}
	}, [state]);

	const WrapperComponent: any = isMobile ? Simple : Modal;

	return (
		<div className="App">
			{
				createElement(
					WrapperComponent,
					{
						className: "cs-main",
						open: true,
						centered: !isMobile,
						onCancel: handleClose,
						width: 680,
						footer: action,
					},
					[
						<Page
							key={state}
							title={name}
							subTitle={organizationName}
							// tags={[{ color: "blue", title: "Новый" }]}
							isLoading={isStateLoading}
							action={isMobile ? action : ""}
						>
							{
								createElement(
									States.get(state),
									{
										icon,
										onFinish: stateRefreshAsync,
										title: name,
										description: state,
										result: result ?? {}
									},
									[]
								)
							}
						</Page>
					]
				)
			}
		</div>
	);
}

export default App;
