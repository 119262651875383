import { FC, ReactElement } from "react";

const State: FC<{ children: ReactElement }> = ({ children }): JSX.Element => {
	return (
		<div style={{ padding: "20px 0" }}>
			{children}
		</div>
	);
}

export default State;

State.displayName = "State";
