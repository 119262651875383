import { useRequest } from "ahooks";

import service from "../../api/HTTPClient";

const useSign = (options?: ObjectRecord) => {
    const request = (formData: ObjectRecord) => {
		return new Promise((resolve, reject) => {
			service.post("start_sign", formData)
				.then(resolve)
				.catch(reject);
		});
	};

	return useRequest(request, options)
}

export default useSign;
