//@ts-nocheck
import { FC, useCallback, useState } from 'react';
import { Button, Form, Checkbox, Divider, Typography, Drawer, Row, Col } from 'antd';
import { DownloadOutlined, FileSearchOutlined } from '@ant-design/icons';
import { isIOS, isMobile } from "react-device-detect";

import qrCode from './qr_download_goskluch.png'
import { useSign, useDownload } from '../../../hooks';
import PDFViewer from '../../PDFViewer/PDFViewer';
import State from "../../../containers/State";

const { Title } = Typography;

type File = {
	url: string,
	filename?: string
}


// TODO: Move to heleprs
const handleDownload = (file: File): void => {
	const url = file?.url;
	const filename = file?.filename;

	const link = document.createElement("a");

	link.href = url;
	link.download = filename;
	link.click();
};

const initModalState = { isOpen: false, url: "", title: "" };

const Created: FC<StateProps> = ({ onFinish }: { onFinish: () => {} }): JSX.Element => {
	const [isRemeber, setIsRemember] = useState<boolean>(false);
	const [modal, setModal] = useState<ObjectRecord>(initModalState);

	const {
		loading: signLoading,
		runAsync: startSign
	} = useSign({ manual: true });

	const {
		loading: fileDownloading,
		run: handleFileDownload,
	} = useDownload({
		isIOS,
		manual: true,
		onSuccess: handleDownload
	});

	const {
		loading: fileLoading,
		run: handleFilePreview,
	} = useDownload({
		manual: true,
		onSuccess: (file: File) => {
			setModal({
				isOpen: true,
				url: file?.url,
				title: file?.filename
			});
		}
	});

	const handleFinish = useCallback((formData: ObjectRecord) => {
		startSign(JSON.stringify({ document: { "data": { "birthday": "2022-12-03T23:51:37.026Z", "number": 123 } } }))
			.finally(onFinish)
	}, [startSign, onFinish]);

	return (
		<State>
			<Form
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 24 }}
				layout="vertical"
				onFinish={handleFinish}
				// onFinishFailed={onFinishFailed}
				onValuesChange={(values: ObjectRecord) => setIsRemember(values?.remember)}
				autoComplete="off"
			>
				<Row>
					<Col span={24}>
						<Title level={5}>
							Перед тем, как направить документ на подпись в Госключ, ознакомьтесь с его содержимым
						</Title>
					</Col>

					<Col span={24}>
						<Row>
							<Col span={24} lg={{ span: 12 }}>
								<Button
									loading={fileDownloading}
									disabled={fileDownloading}
									type="link"
									icon={<DownloadOutlined />}
									onClick={() => handleFileDownload(JSON.stringify({ document: { "data": { "birthday": "2022-12-03T23:51:37.026Z", "number": 123 } } }))}
								>
									Скачать документ
								</Button>
							</Col>

							<Col span={24} lg={{ span: 12 }}>
								<Button
									loading={fileLoading}
									disabled={fileLoading}
									type="link"
									icon={<FileSearchOutlined />}
									onClick={() => handleFilePreview(
											JSON.stringify({
												document: { "data": { "birthday": "2022-12-03T23:51:37.026Z", "number": 123 } } }
											)
										)
									}
								>
									Посмотреть документ
								</Button>
							</Col>
						</Row>
					</Col>

					<Divider />

					<Col span={24}>
						<Row justify="space-between" align="bottom">
							<Col span={24}>
								<Row>
									<Col span={24} lg={{ span: 16 }}>
										<Col span={24}>
											<Form.Item name="remember" valuePropName="checked">
												<Checkbox>Документ прочитал, согласен, готов подписать</Checkbox>
											</Form.Item>
										</Col>
										<Col span={12} xs={{ span: 24 }}>
											<Form.Item>
												<Button
													loading={signLoading}
													disabled={!isRemeber || signLoading}
													type="primary"
													htmlType="submit"
												>
													Направить в Госключ
												</Button>
											</Form.Item>
										</Col>
									</Col>
									<Col span={24} lg={{ span: 8 }}>
										<Row justify={"center"}>
											{
												!isMobile && (
													<Col xs={{ span: 24 }}>
														<Row justify={"center"}>
															<img
																src={qrCode}
																alt="gq-code"
																style={{ width: 150 }}
															/>
														</Row>
													</Col>
												)
											}
											<Col xs={{ span: 24 }}>
												<Row justify={"center"}>
													{
														isMobile && (
															<Button type={"link"} href={"https://api.cloudsigns.ru/qr/download_goskluch"} target={"_blank"}>
																Скачать приложение Госключ.
															</Button>
														)
													}

													{
														!isMobile && (
															<div style={{ fontSize: 12 }}>
																Скачать приложение Госключ.
															</div>
														)
													}
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>

			<Drawer
				destroyOnClose
				className={"cs-pdf"}
				title={modal?.title}
				open={modal?.isOpen}
				onClose={() => setModal(initModalState)}
				height={window?.innerHeight}
				footer={null}
				placement={"bottom"}
			>
				<PDFViewer
					id={modal?.title}
					title={modal?.title}
					url={modal?.url}
				/>
			</Drawer>
		</State>
	);
}

export default Created;

Created.displayName = "CreatedState";
